export const formatTimeToString = (time: string) => {
  const inputDate = new Date(time);
  const hours = inputDate.getHours().toString().padStart(2, '0');
  const minutes = inputDate.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const formatStringToTime = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);

  const currentDate = new Date();
  currentDate.setHours(hours, minutes, 0, 0);
  return currentDate
}