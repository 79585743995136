import { Edit } from "react-admin";
import EventFormInputs from "./EventFormInputs";

const EventEdit = () => {
  return (
    <Edit>
      <EventFormInputs isEdit />
    </Edit>
  );
};

export default EventEdit;