import { Create } from "react-admin";
import OfferFormInputs from "./OfferFormInputs";

const OfferCreate = () => {
  return (
    <Create>
      <OfferFormInputs isCreate />
    </Create>
  );
};

export default OfferCreate;