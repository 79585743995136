import { Create } from "react-admin";
import LocationFormInputs from "./LocationFormInputs";

const LocationCreate = () => {
  return (
    <Create>
      <LocationFormInputs isCreate />
    </Create>
  );
};

export default LocationCreate;
