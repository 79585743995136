export enum ELocalStoreKeys {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  CACHE_TTL_USER = 86400,
}

export enum EAuthPaths {
  LOGIN = "auth/login/admin",
  LOGOUT = "auth/logout/admin",
  GET_ME = "users/my",
}

export interface IFormInputs {
  isEdit?: boolean;
  isCreate?: boolean;
}

export enum SpecificResources {
  Restaurants = "restaurants",
  Offers = "offers",
  Events = "events",
  Chats = "chats",
  RestaurantsTypes = "restaurantsTypes",
  Locations = "locations",
}

export enum ChatUsersRoles {
  Participant = "PARTICIPANT",
  Owner = "OWNER",
  Banned = "BANNED",
}
