import { Show, SimpleShowLayout, TextField } from "react-admin";

const LocationShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="country" label="Location country" />
        <TextField source="city" label="Location city" />
      </SimpleShowLayout>
    </Show>
  );
};

export default LocationShow;
