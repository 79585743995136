import { Box, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import {
  DateTimeInput,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreateContext,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { IFormInputs } from "../../types";
import { renderLocationOptions } from "../../utils";

const EventFormInputs: FC<IFormInputs> = ({ isEdit, isCreate }) => {
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();
  const record = useRecordContext();

  const transformedRecord = useMemo(() => {
    if (!record) return null;

    const coordinates = record?.geo?.coordinates;

    if (!coordinates) return null;

    const [lat, lon] = coordinates;

    return { ...record, lat, lon };
  }, [record]);

  const onSubmit = (data) => {
    const { lat, lon, imageUrl, ...rest } = data;
    const geo = {
      type: "Point",
      coordinates: [lat, lon],
    };

    let formattedData = {
      ...rest,
      geo,
    };

    if (typeof imageUrl !== "string") {
      formattedData = { ...formattedData, imageUrl };
    }

    if (isEdit) {
      saveOnEdit(formattedData);
      return;
    }

    if (isCreate) {
      saveOnCreate(formattedData);
      return;
    }
  };
  return (
    <SimpleForm record={transformedRecord} onSubmit={onSubmit}>
      <TextInput type="text" source="title" validate={required()} />

      <TextInput type="text" multiline fullWidth source="description" validate={required()} />

      <Box>
        {record && (
          <Labeled>
            <ImageField source="imageUrl" label="Current image" />
          </Labeled>
        )}
      </Box>

      <ImageInput source="imageUrl" label="New image">
        <ImageField source="src" title="title" />
      </ImageInput>

      <TextInput type="text" source="address" validate={required()} />

      <TextInput type="tel" source="phoneNumber" validate={required()} />

      <ReferenceInput source="locationId" reference="locations">
        <SelectInput
          label="Location"
          sx={{
            minWidth: 364,
          }}
          optionText={renderLocationOptions}
          validate={required()}
        />
      </ReferenceInput>

      <Typography variant="body1">Geo:</Typography>
      <Stack spacing="-1rem">
        <NumberInput label="Latitude" source="lat" validate={required()} />
        <NumberInput label="Longitude" source="lon" validate={required()} />
      </Stack>

      <Stack direction="row" spacing="0.5rem">
        <DateTimeInput source="startDate" validate={required()} />
        <DateTimeInput source="endDate" validate={required()} />
      </Stack>

      <NumberInput source="participantsLimit" validate={required()} />
    </SimpleForm>
  );
};

export default EventFormInputs;
