import { Edit } from "react-admin";
import OfferFormInputs from "./OfferFormInputs";

const OfferEdit = () => {
  return (
    <Edit>
      <OfferFormInputs isEdit />
    </Edit>
  );
};

export default OfferEdit;