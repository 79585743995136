import { Datagrid, EditButton, ImageField, List, TextField } from "react-admin";

const OfferList = () => {
  return (
    <List perPage={25} exporter={false} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" />

        <ImageField source="imageUrl" sortable={false} />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default OfferList;