import { Create } from "react-admin";
import RestaurantFormInputs from "./RestaurantFormInputs";

const RestaurantCreate = () => {
  return (
    <Create>
      <RestaurantFormInputs isCreate />
    </Create>
  );
};

export default RestaurantCreate;