import { axiosInstance } from "@/middleware/axios";

class ChatService {
  apiUrl = "/chats";

  kickUser(chatId: string, userId: number) {
    return axiosInstance.delete(this.apiUrl + `/${chatId}/kick`, { data: { id: userId } });
  }

  banUser(chatId: string, userId: number) {
    return axiosInstance.post(this.apiUrl + `/${chatId}/ban`, { userId });
  }

  unbanUser(chatId: string, userId: number) {
    return axiosInstance.post(this.apiUrl + `/${chatId}/unban`, { userId });
  }

  recalculateMessages(chatId: string) {
    return axiosInstance.patch(this.apiUrl + `/${chatId}/recalculate-messages`);
  }
}

const chatService = new ChatService();
export default chatService;
