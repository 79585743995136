import { Datagrid, EditButton, List, ShowButton, TextField } from "react-admin";

const RestaurantList = () => {
  return (
    <List perPage={25} exporter={false} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField type="number" source="id" />
        <TextField source="name" />

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default RestaurantList;