import { stringify } from "qs";
import { DataProvider, HttpError } from "react-admin";
import { axiosInstance } from "../../axios";
import { SpecificResources } from "@/types";

const resourceListMapper = {
  [SpecificResources.Restaurants]: "restaurants",
  [SpecificResources.Offers]: "offers/admin",
  [SpecificResources.Events]: "events/admin",
  [SpecificResources.Chats]: "chats/admin",
  [SpecificResources.RestaurantsTypes]: "restaurants/types",
  [SpecificResources.Locations]: "locations/admin",
};

const getManySpecificResources = [SpecificResources.RestaurantsTypes, SpecificResources.Locations];

export const baseDataProvider: DataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: JSON.stringify({
        [field]: order,
      }),
      page: page,
      limit: perPage,
    };
    const url = `${resourceListMapper[resource]}?${stringify(query)}`;
    return axiosInstance(url)
      .then((res) => {
        if (resource === SpecificResources.RestaurantsTypes) {
          const { data: resData } = res;
          const formattedData = resData.map((el, index) => ({ id: index, name: el }));
          return { data: formattedData, total: formattedData.length };
        }

        const { data: resData } = res;
        const { data, total } = resData;
        return {
          data: data,
          total: total,
        };
      })
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  getOne: (resource, params) =>
    axiosInstance(`${resource}/${params.id}`)
      .then(({ data }) => ({ data }))
      .catch((e) => {
        const { response } = e;
        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }
        throw e;
      }),

  getMany: (resource, params) => {
    const query = {
      filter: { ids: params.ids },
    };
    const url = `${
      getManySpecificResources.includes(resource as SpecificResources) ? resourceListMapper[resource] : resource
    }?${stringify(query)}`;
    return axiosInstance(url)
      .then(({ data }) => {
        if (resource === SpecificResources.RestaurantsTypes) {
          const formattedData = data.map((el, index) => ({ id: index, name: el }));
          return { data: formattedData, total: data.length };
        }

        const { data: responseData, total } = data;
        return {
          data: responseData,
          total: total,
        };
      })
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: {
        [field]: order,
      },
      page: page,
      limit: perPage,
      filter: {
        ...params.filter,
        [params.target]: params.id,
      },
    };
    const url = `${resource}?${stringify(query)}`;

    return axiosInstance(url)
      .then(({ data }) => {
        const { data: responseData, total } = data;
        return {
          data: responseData,
          total: total,
        };
      })
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  create: (resource, params) => {
    return axiosInstance(`${resource}`, {
      method: "POST",
      data: resource === SpecificResources.Restaurants ? params.data.formData : params.data,
      headers: {
        "Content-Type": resource === SpecificResources.Restaurants ? "multipart/form-data" : "application/json",
      },
    })
      .then(({ data }) => ({
        data: { ...params.data, ...data, id: Number(data.id) },
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  update: (resource, params) =>
    axiosInstance(`${resource}/${params.id}`, {
      method: "PATCH",
      data: resource === SpecificResources.Restaurants ? params.data.formData : params.data,
      headers: {
        "Content-Type": resource === SpecificResources.Restaurants ? "multipart/form-data" : "application/json",
      },
    })
      .then(({ data }) => {
        return {
          data: { ...data, id: Number(data.id) },
        };
      })
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      }),

  updateMany: (resource, params) => {
    const query = {
      filter: { id: params.ids },
    };
    return axiosInstance(`${resource}?${stringify(query)}`, {
      method: "PATCH",
      data: params.data,
    })
      .then(({ data }) => ({ data }))
      .catch((e) => {
        const { response } = e;
        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },

  delete: (resource, params) =>
    axiosInstance(`${resource}/${params.id}`, {
      method: "DELETE",
    })
      .then(({ data }) => ({
        data: data,
      }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      }),

  deleteMany: (resource, params) => {
    const query = {
      filter: { ids: params.ids },
    };
    return axiosInstance(`${resource}?${stringify(query)}`, {
      method: "DELETE",
    })
      .then(({ data }) => ({ data }))
      .catch((e) => {
        const { response } = e;

        if (response?.data) {
          const { message, status } = response.data;
          throw new HttpError(message, status, e.response.data);
        }

        throw e;
      });
  },
};
