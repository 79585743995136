import { Datagrid, EditButton, List, TextField, NumberField, ShowButton } from "react-admin";

const ChatList = () => {
  return (
    <List perPage={25} exporter={false} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />

        <NumberField source="messagesCount" sortable={false}/>

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ChatList;