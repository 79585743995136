import { IFormInputs } from "@/types";
import { Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  required,
  email,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreateContext,
  useEditContext,
  useNotify,
  useRecordContext,
  SelectInput,
} from "react-admin";
import { PHONE_NUMBER_REGEX } from "../../constants/regex.constant";
import restaurantService from "../../services/restaurant.service";
import { isRawFile, renderLocationOptions } from "../../utils";

const phoneValidation = (value: string) => {
  if (!value) {
    return "The phone number is required";
  }
  if (!value.match(PHONE_NUMBER_REGEX)) {
    return "Must be a phone number";
  }
  return undefined;
};

const RestaurantFormInputs: FC<IFormInputs> = ({ isEdit, isCreate }) => {
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();

  const record = useRecordContext();
  const notify = useNotify();

  const transformedRecord = useMemo(() => {
    if (!record || !record.geo) return null;

    const formatImages = record.images?.map((el) => ({ src: el })) || [];

    const coordinates = record?.geo?.coordinates;

    if (!coordinates) return null;

    const [lat, lon] = coordinates;

    return { ...record, images: formatImages, lat, lon };
  }, [record]);

  const onSubmit = (data) => {
    const { lat, lon, images, recommendations, ...rest } = data;
    const geo = {
      type: "Point",
      coordinates: [lat, lon],
    };

    const recommendationsImages = recommendations?.map(({ imageUrl }) => imageUrl);

    const formattedRecommendationsImages = recommendationsImages?.filter((item) => typeof item !== "string");

    const recommendationsName = recommendations.map(({ name, id, imageUrl }) => {
      let imageIndex;
      if (typeof imageUrl !== "string") {
        imageIndex = formattedRecommendationsImages.map(({ rawFile }) => rawFile).indexOf(imageUrl.rawFile);
      }
      return {
        name,
        id,
        imageIndex,
      };
    });

    let formattedData = {
      ...rest,
      recommendations: JSON.stringify(recommendationsName),
      geo: JSON.stringify(geo),
    };

    const formData = new FormData();

    if (formattedRecommendationsImages && formattedRecommendationsImages.length) {
      for (const image of formattedRecommendationsImages) {
        formData.append("recommendationPhotos", image.rawFile);
      }
    }

    for (let key in formattedData) {
      const value = formattedData[key];

      if (value !== null && value !== undefined) {
        formData.append(key, formattedData[key]);
      }
    }

    const formattedImages = images?.filter((item) => isRawFile(item.src));

    let sortedImages = {};
    if (formattedImages && formattedImages.length) {
      sortedImages = { images: formattedImages };
    }

    if (isEdit) {
      saveOnEdit({ formData, sortedImages });
      return;
    }

    if (isCreate) {
      saveOnCreate({ formData, sortedImages });
      return;
    }
  };

  const handleDisableAdd = (formData: any): boolean => {
    const prevData = { ...transformedRecord } as any;

    if (!prevData.recommendations || !formData.recommendations) {
      return false;
    }

    if (prevData.recommendations.length > formData.recommendations.length) {
      notify(`You need to save restaurant before add the new recommendation`, { type: "error" });
    }

    return prevData.recommendations.length > formData.recommendations.length;
  };

  const handleDeleteImage = async ({ src }: { src: string }) => {
    try {
      if (isRawFile(src)) {
        return null;
      }

      await restaurantService.deleteImage(src, record.id as number);

      notify(`Image was deleted`, { type: "success" });
    } catch (e) {
      notify(String(e), { type: "error" });
      console.log(e);
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit} record={transformedRecord}>
      <TextInput type="text" source="name" isRequired validate={required()} />
      <TextInput type="text" source="description" multiline isRequired fullWidth validate={required()} />
      <TextInput type="text" source="address" validate={required()} />
      <TextInput type="tel" source="phoneNumber" isRequired validate={phoneValidation} />
      <TextInput type="text" source="ownerEmail" validate={email()} />

      <ReferenceInput source="locationId" reference="locations">
        <SelectInput
          label="Location"
          sx={{
            minWidth: 364,
          }}
          optionText={renderLocationOptions}
          validate={required()}
        />
      </ReferenceInput>

      <Typography variant="body1">Geo:</Typography>
      <Stack spacing="-1rem">
        <NumberInput label="Latitude" source="lat" validate={required()} />
        <NumberInput label="Longitude" source="lon" validate={required()} />
      </Stack>

      <ReferenceInput source="type" reference="restaurantsTypes" validate={required()}>
        <AutocompleteInput
          source=""
          label="Type"
          sx={{
            minWidth: 364,
          }}
          filterToQuery={(q) => ({ name: q })}
          optionValue="name"
          optionText="name"
          isRequired
        />
      </ReferenceInput>

      <FormDataConsumer>
        {({ formData }) => (
          <ArrayInput source="recommendations">
            <SimpleFormIterator
              inline
              disableReordering
              getItemLabel={(index) => `#${index + 1}`}
              disableAdd={handleDisableAdd(formData)}
            >
              <TextInput fullWidth source="name" type="text" isRequired validate={required()} />
              {record && <ImageField source="imageUrl" label="Current image" />}
              <ImageInput source="imageUrl" label="New Image" isRequired validate={required()}>
                <ImageField source="src" title="title" />
              </ImageInput>
              )
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormDataConsumer>

      <ImageInput source="images" label="Restaurant images" multiple onRemove={handleDeleteImage}>
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  );
};

export default RestaurantFormInputs;
