import { Edit } from "react-admin";
import RestaurantFormInputs from "./RestaurantFormInputs";

const RestaurantEdit = () => {
  return (
    <Edit>
      <RestaurantFormInputs isEdit />
    </Edit>
  );
};

export default RestaurantEdit;