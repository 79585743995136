import { Edit } from "react-admin";
import LocationFormInputs from "./LocationFormInputs";

const LocationEdit = () => {
  return (
    <Edit>
      <LocationFormInputs isEdit />
    </Edit>
  );
};

export default LocationEdit;
