import { Box, Stack } from "@mui/material";
import { FC, useMemo } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TimeInput,
  useCreateContext,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { SELECT_OFFER_DAYS, SELECT_OFFER_TYPE } from "../../constants";
import { IFormInputs } from "../../types";
import { formatStringToTime, formatTimeToString, renderLocationOptions } from "../../utils";

const OfferFormInputs: FC<IFormInputs> = ({ isEdit, isCreate }) => {
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();
  const record = useRecordContext();

  const transformedRecord = useMemo(() => {
    if (!record) return null;

    const validTime = record?.validTime;

    if (!validTime) return null;

    const { days, startTime, endTime } = validTime;

    return { ...record, days, startTime: formatStringToTime(startTime), endTime: formatStringToTime(endTime) };
  }, [record]);

  const onSubmit = (data) => {
    const { days, startTime, endTime, imageUrl, minTicketAmount, ...rest } = data;

    const validTime = { days, startTime: formatTimeToString(startTime), endTime: formatTimeToString(endTime) };
    let formattedData = { ...rest, validTime, minTicketAmount: Number(minTicketAmount) };

    if (typeof imageUrl !== "string") {
      formattedData = { ...formattedData, imageUrl };
    }

    if (isEdit) {
      saveOnEdit(formattedData);
      return;
    }

    if (isCreate) {
      saveOnCreate(formattedData);
      return;
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit} record={transformedRecord}>
      <TextInput type="text" source="title" validate={required()} />

      <TextInput type="text" multiline fullWidth source="description" validate={required()} />

      <Box>
        {record && (
          <Labeled>
            <ImageField source="imageUrl" label="Current image" />
          </Labeled>
        )}
      </Box>

      <ImageInput source="imageUrl" label="New image">
        <ImageField source="src" title="title" />
      </ImageInput>

      <Stack direction="row" spacing="0.5rem">
        <DateInput source="startDate" validate={required()} />
        <DateInput source="endDate" validate={required()} />
      </Stack>

      <BooleanInput source="isEnabled" />

      <ReferenceInput source="restaurantId" reference="restaurants" validate={required()}>
        <AutocompleteInput
          source="id"
          label="Restaurants"
          sx={{
            minWidth: 364,
          }}
          filterToQuery={(q) => ({ name: q })}
          optionText="name"
          optionValue="id"
        />
      </ReferenceInput>

      <Stack direction="row" spacing="0.5rem">
        <NumberInput source="discount" validate={required()} />

        <SelectInput
          source="offerType"
          choices={SELECT_OFFER_TYPE}
          optionText="name"
          optionValue="value"
          validate={required()}
        />
      </Stack>

      <Stack direction="row" spacing="0.5rem">
        <SelectArrayInput
          source="days"
          choices={SELECT_OFFER_DAYS}
          optionText="name"
          optionValue="value"
          validate={required()}
        />

        <TimeInput source="startTime" validate={required()} />
        <TimeInput source="endTime" validate={required()} />
      </Stack>

      <NumberInput label="Minimum Ticket Amount" source="minTicketAmount" validate={required()} />

      <ReferenceInput source="locationId" reference="locations">
        <SelectInput
          // emptyValue={undefined}
          label="Location"
          sx={{
            minWidth: 364,
          }}
          optionText={renderLocationOptions}
          validate={isEdit && required()}
          helperText={isCreate && "This field is optional. Restaurant location will be used by default"}
        />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default OfferFormInputs;
