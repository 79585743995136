import { addRefreshAuthToDataProvider, CreateParams, DataProvider, UpdateParams } from "react-admin";
import { SessionService } from "@/utils";
import { SpecificResources } from "@/types";
import { axiosInstance } from "../../axios";
import { baseDataProvider } from "./base-data-provider";

const updateRestaurantImages = async (resource: string, params: CreateParams | UpdateParams, restaurantId: string) => {
  const formData = new FormData();

  for (const image of params.data.sortedImages.images) {
    formData.append("photos", image.rawFile);
  }


  return await axiosInstance(`${resource}/${restaurantId}/images`, {
    method: "PATCH",
    data: formData,
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then(({ data }) => ({
    data: { ...data },
  }));
};

const updateImage = async (resource: string, params: CreateParams | UpdateParams, id: string) => {
  const formData = new FormData();
  formData.append("photo", params.data.imageUrl.rawFile);

  return await axiosInstance(`${resource}/${id}/image`, {
    method: "PATCH",
    data: formData,
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then(({ data }) => ({
    data: { ...data },
  }));
};

const extendedCreate: DataProvider["create"] = async (resource, params) => {
  switch (resource) {
    case SpecificResources.Restaurants: {
      const createdInstance = await baseDataProvider.create(resource, params);
      if (params.data.sortedImages.images) {
        return await updateRestaurantImages(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Offers: {
      const createdInstance = await baseDataProvider.create(resource, params);

      if (params.data.imageUrl) {
        return await updateImage(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Events: {
      const createdInstance = await baseDataProvider.create(resource, params);

      if (params.data.imageUrl) {
        return await updateImage(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }

    default:
      return await baseDataProvider.create(resource, params);
  }
};

const extendedUpdate: DataProvider["update"] = async (resource, params) => {
  switch (resource) {
    case SpecificResources.Restaurants: {
      const createdInstance = await baseDataProvider.update(resource, params);
      if (params.data.sortedImages.images) {
        return await updateRestaurantImages(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Offers: {
      const createdInstance = await baseDataProvider.update(resource, params);

      if (params.data.imageUrl) {
        return await updateImage(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Events: {
      const createdInstance = await baseDataProvider.update(resource, params);

      if (params.data.imageUrl) {
        return await updateImage(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    case SpecificResources.Chats: {
      const createdInstance = await baseDataProvider.update(resource, params);

      if (params.data.imageUrl) {
        return await updateImage(resource, params, createdInstance.data.id);
      }

      return createdInstance;
    }
    default:
      return await baseDataProvider.update(resource, params);
  }
};

const extendedDataProvider: DataProvider = {
  ...baseDataProvider,
  create: extendedCreate,
  update: extendedUpdate,
};

export const dataProviderWithRefresh = addRefreshAuthToDataProvider(extendedDataProvider, SessionService.refresh);
