import { Datagrid, EditButton, List, TextField } from "react-admin";

const LocationList = () => {
  return (
    <List perPage={25} exporter={false} sort={{ field: "id", order: "ASC" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="country" />
        <TextField source="city" />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default LocationList;
