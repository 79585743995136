import { FC, ReactElement } from 'react';
import { CircularProgress, Stack } from '@mui/material';

interface ILoadable {
  children: ReactElement;
  isLoading: boolean;
}

const Loadable: FC<ILoadable> = ({
  children,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return <>{children}</>;
};

export default Loadable;
