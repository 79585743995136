import { ChatUsersRoles, IChatUsersList } from "@/types";
import { Button, Grid, List, ListItem, ListItemText, ListSubheader, Stack, Typography, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotify, useRecordContext, useTheme } from "react-admin";
import Loadable from "@/layouts/Loadable";
import chatService from "@/services/chats.service";

const ChatUsersList = () => {
  const record = useRecordContext<IChatUsersList | null>();
  const notify = useNotify();
  const [theme] = useTheme();

  const [chatList, setChatList] = useState<IChatUsersList | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const textColor = theme === "dark"
    ? "#fff"
    : "#000";
  const textColorForBanned = theme === "dark"
    ? "#cccccc"
    : "rgba(0, 0, 0, 0.57)";
  const hoverBackgroundColor = theme === "dark" ? "#1a1a1a" : "#f5f5f5";

  const handleKickUser = async (userId: number) => {
    try {
      const { data } = await chatService.kickUser(String(chatList.id), userId);

      if (data.success) {
        notify(`User was kicked`, { type: "success" });
        const users = chatList.users.filter(user => user.userId !== userId);
        setChatList({ ...chatList, users });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBanUser = async (userId: number) => {
    try {
      const { data } = await chatService.banUser(String(chatList.id), userId);

      const userIndex = chatList.users.findIndex(user => user.userId === userId);

      if (userIndex !== -1 && data.success) {
        const updatedUsers = [...chatList.users];
        updatedUsers[userIndex] = { ...updatedUsers[userIndex], role: ChatUsersRoles.Banned };
        setChatList({ ...chatList, users: updatedUsers });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnbanUser = async (userId: number) => {
    try {
      const { data } = await chatService.unbanUser(String(chatList.id), userId);

      const userIndex = chatList.users.findIndex(user => user.userId === userId);

      if (userIndex !== -1 && data.success) {
        const updatedUsers = [...chatList.users];
        updatedUsers[userIndex] = { ...updatedUsers[userIndex], role: ChatUsersRoles.Participant };

        setChatList({ ...chatList, users: updatedUsers });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (record) {
      setChatList(record);
      setIsLoading(false);
    }
  }, [record]);

  return (
    <List subheader={<ListSubheader>Users</ListSubheader>} sx={{ width: "100%" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        paddingX="1rem"
        paddingY="0.5rem"
      >
        <Grid item xs>
          <Typography fontWeight="bold">Name</Typography>
        </Grid>

        <Grid item xs>
          <Typography fontWeight="bold">Nickname</Typography>
        </Grid>

        <Grid item xs>
        </Grid>
      </Grid>

      <Divider color="#f0f0f0" />

      <Loadable isLoading={isLoading}>
        <>
          {chatList?.users?.map(({ user, role, id, userId }) =>
            <>
              <ListItem key={id} sx={{
                color: role === ChatUsersRoles.Banned ? textColorForBanned : textColor,
                "&:hover": {
                  backgroundColor: hoverBackgroundColor,
                },
              }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <ListItemText>{user.name}</ListItemText>
                  </Grid>

                  <Grid item xs>
                    <ListItemText>{user.nickname}</ListItemText>
                  </Grid>

                  <Grid item xs>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing="1rem">
                      {role === ChatUsersRoles.Banned ?
                        <Button
                          variant="contained"
                          onClick={() => handleUnbanUser(userId)}>
                          Unban
                        </Button>
                        :
                        <Button
                          variant="contained"
                          onClick={() => handleBanUser(userId)}>
                          Ban
                        </Button>}
                      <Button color="error" variant="text" onClick={() => handleKickUser(userId)}
                              disabled={role === ChatUsersRoles.Banned}>Kick</Button>

                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider color="#f0f0f0" />
            </>)}
        </>
      </Loadable>
    </List>
  );
};

export default ChatUsersList;