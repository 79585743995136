import { axiosInstance } from "@/middleware/axios";

class RestaurantService {
  apiUrl = "/restaurants";

  deleteImage(imageUrl: string, restaurantId: number) {
    return axiosInstance.delete(this.apiUrl + `/${restaurantId}/image`, { data: { imageUrl } });
  }
}

const restaurantService = new RestaurantService();
export default restaurantService;
