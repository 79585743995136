import { useMemo } from "react";
import {
  ArrayField,
  Datagrid,
  ImageField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";

const MySimpleShowLayout = () => {
  const record = useRecordContext();

  const transformedRecord = useMemo(() => {
    if (!record) return null;

    const formatImages = record.images?.map((el) => ({ src: el })) ?? [];

    return { ...record, images: formatImages };
  }, [record]);

  return (
    <SimpleShowLayout record={transformedRecord}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="type" />

      <ArrayField source="recommendations">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <ImageField source="imageUrl" />
        </Datagrid>
      </ArrayField>

      <ImageField source="images" src="src" label="Images" />

      <ReferenceField source="locationId" reference="locations" />
    </SimpleShowLayout>
  );
};

const RestaurantShow = () => {
  return (
    <Show>
      <MySimpleShowLayout />
    </Show>
  );
};

export default RestaurantShow;
