import { FC } from "react";
import { required, SimpleForm, TextInput, useCreateContext, useEditContext } from "react-admin";
import { IFormInputs } from "../../types";

const LocationFormInputs: FC<IFormInputs> = ({ isEdit, isCreate }) => {
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();

  const onSubmit = (data) => {
    if (isEdit) {
      saveOnEdit(data);
      return;
    }

    if (isCreate) {
      saveOnCreate(data);
      return;
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput
        multiline
        type="text"
        source="country"
        sx={{
          minWidth: 364,
        }}
        validate={required()}
      />

      <TextInput
        multiline
        type="text"
        source="city"
        sx={{
          minWidth: 364,
        }}
        validate={required()}
      />
    </SimpleForm>
  );
};

export default LocationFormInputs;
