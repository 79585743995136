import { Box } from "@mui/material";
import { FC } from "react";
import {
  ImageField,
  ImageInput,
  Labeled,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { IFormInputs } from "@/types";
import { renderLocationOptions } from "../../utils";

const ChatFormInputs: FC<IFormInputs> = ({ isEdit, isCreate }) => {
  const { save: saveOnEdit } = useEditContext();
  const record = useRecordContext();

  const onSubmit = (data) => {
    const { lat, lon, imageUrl, ...rest } = data;
    let formattedData = { ...rest };

    if (typeof imageUrl !== "string") {
      formattedData = { ...formattedData, imageUrl };
    }

    if (isEdit) {
      saveOnEdit(formattedData);
      return;
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput source="name" label="Chat name" />

      <Box>
        {record && (
          <Labeled>
            <ImageField source="imageUrl" label="Current image" />
          </Labeled>
        )}
      </Box>

      <ImageInput source="imageUrl" label="New image">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ReferenceInput source="locationId" reference="locations">
        <SelectInput
          label="Location"
          sx={{
            minWidth: 364,
          }}
          optionText={renderLocationOptions}
          validate={required()}
        />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default ChatFormInputs;
