import { Create } from "react-admin";
import EventFormInputs from "./EventFormInputs";

const EventCreate = () => {
  return (
    <Create>
      <EventFormInputs isCreate />
    </Create>
  );
};

export default EventCreate;