import Button from "@mui/material/Button";
import { useState } from "react";
import {
  EditButton,
  ImageField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
} from "react-admin";
import chatService from "../../services/chats.service";
import ChatUsersList from "./ChatUsersList";

const PostShowActions = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleRecalculateChatMessages = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await chatService.recalculateMessages(String(record.id));

      if (data.success) {
        notify(`Chat messages was recalculated`, { type: "success" });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TopToolbar>
      <EditButton />
      <Button
        sx={{ fontSize: "0.8rem", padding: "4px 8px" }}
        onClick={handleRecalculateChatMessages}
        disabled={isLoading}
      >
        Recalculate messages
      </Button>
    </TopToolbar>
  );
};

const ChatShow = () => {
  return (
    <Show actions={<PostShowActions />}>
      <SimpleShowLayout>
        <TextField source="name" label="Chat name" fontSize="2rem" />
        <ImageField source="imageUrl" label="Image" />
        <ReferenceField source="locationId" reference="locations" />

        <ChatUsersList />
      </SimpleShowLayout>
    </Show>
  );
};

export default ChatShow;
