import React from "react";
import { Admin, Resource } from "react-admin";
import { ToastContainer } from "react-toastify";
import { authProviderWithRefresh, dataProviderWithRefresh } from "./middleware";
import { BrowserRouter } from "react-router-dom";
import ChatEdit from "./pages/Chats/ChatEdit";
import ChatList from "./pages/Chats/ChatList";
import ChatShow from "./pages/Chats/ChatShow";
import EventCreate from "./pages/Events/EventCreate";
import EventEdit from "./pages/Events/EventEdit";
import EventList from "./pages/Events/EventList";
import LocationCreate from "./pages/Locations/LocationCreate";
import LocationList from "./pages/Locations/LocationList";
import LocationShow from "./pages/Locations/LocationShow";
import OfferCreate from "./pages/Offers/OfferCreate";
import OfferEdit from "./pages/Offers/OfferEdit";
import OfferList from "./pages/Offers/OfferList";
import RestaurantCreate from "./pages/Restaraunts/RestaurantCreate";
import RestaurantEdit from "./pages/Restaraunts/RestaurantEdit";
import RestaurantList from "./pages/Restaraunts/RestaurantList";
import RestaurantShow from "./pages/Restaraunts/RestaurantShow";

import {
  Restaurant as RestaurantIcon,
  Event as EventIcon,
  LocalOffer as OfferIcon,
  Chat as ChatIcon,
  Place as PlaceIcon,
} from "@mui/icons-material";
import LocationEdit from "./pages/Locations/LocationEdit";

export const App = () => (
  <BrowserRouter>
    <Admin
      dataProvider={dataProviderWithRefresh}
      authProvider={authProviderWithRefresh}
      darkTheme={{ palette: { mode: "dark" } }}
    >
      <Resource
        name="restaurants"
        create={RestaurantCreate}
        show={RestaurantShow}
        edit={RestaurantEdit}
        list={RestaurantList}
        icon={RestaurantIcon}
        recordRepresentation="restaurants"
      />

      <Resource
        name="offers"
        create={OfferCreate}
        edit={OfferEdit}
        list={OfferList}
        icon={OfferIcon}
        recordRepresentation="name"
      />

      <Resource name="events" create={EventCreate} edit={EventEdit} list={EventList} icon={EventIcon} />

      <Resource name="chats" show={ChatShow} edit={ChatEdit} list={ChatList} icon={ChatIcon} />

      <Resource
        name="locations"
        create={LocationCreate}
        show={LocationShow}
        edit={LocationEdit}
        list={LocationList}
        icon={PlaceIcon}
        recordRepresentation={(record) => `${record?.city}, ${record?.country}`}
      />
    </Admin>

    <ToastContainer />
  </BrowserRouter>
);

export default App;
