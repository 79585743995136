import { Edit } from "react-admin";
import ChatFormInputs from "./ChatFormInputs";

const ChatEdit = () => {
  return (
    <Edit>
      <ChatFormInputs isEdit/>
    </Edit>
  );
};

export default ChatEdit;