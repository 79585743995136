import axios from "axios";
import { SessionService } from "../../utils";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const getToken = () => {
  const { accessToken } = SessionService.get();
  return accessToken;
};

const handleRequestFulfilled = (config: any) => {
  const accessToken = getToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

axiosInstance.interceptors.request.use(handleRequestFulfilled);